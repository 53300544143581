import { Link, useParams } from "react-router-dom"
import pick1 from '../components/pick1.png'
import pick2 from '../components/pick2.png'
import pick3 from '../components/pick3.png'
import pick4 from '../components/pick4.png'
import pick5 from '../components/pick5.png'
import search1 from '../components/search1.png'
import search2 from '../components/search2.png'
import { useContext, useEffect, useState } from "react"
import { link } from "./link"
import { LangContext } from "../components/Language"
import axios from "axios"


export function Introduction(){
    const {id} = useParams();
    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(false);
    const lang = useContext(LangContext)
    useEffect(() => {
        setLoading(true);
        axios.get(link.baseURL + '/app/ticket').then((res) => {
            setCart(res.data.ticket);
            setLoading(false);
        }).catch((err) => {
            //popup(lang.pick_fault);
            console.error(err);
        });
    }, []);
    if(id == 0){
        return(
            <>
            <h3 className="mt-2">Các bước để có thể mua vé:</h3>
            <ol>
                <li>Ấn vào nút "Đặt vé" ở trên cùng:</li>
                <img src={pick1} style={{border: 'solid 1px black'}}/>
                <li>Điền thông tin bản thân và ngày sử dụng vé để mua vé. Nhấn nút hoàn thành:</li>
                <img src={pick2} width={600}/>
                <li>Chọn vé và số lượng vé bạn cần mua. Tất cả những vé được chọn sẽ được thanh toán cùng một lúc:</li>
                <img src={pick3} />
                <li>Bấm vào nút "Đăng ký" ở cuối cùng:</li>
                <img src={pick4} />
                <li>Xem lại thông tin đặt vé của bạn, bấm nút "Thanh toán" để trả tiền:</li>
                <img src={pick5} />
                <li>Bạn sẽ được chuyển sang màn hình thanh toán. <Link to={'/intro/1'}>Hướng dẫn thanh toán VNPAY</Link></li>
            </ol>

            <h3 className="mt-2">Tra cứu vé đã mua:</h3>
            <ol>
                <li>Ấn vào nút "Tìm kiếm vé đã mua" ở trên cùng:</li>
                <img src={search1} />
                <li>Điền bất kỳ thông tin cá nhân nào mà bạn đã dùng để đặt vé. Nhấn nút "Gửi thông tin":</li>
                <img src={search2} />
            </ol>
            </>
        );
    }
    else if(id == 1) return(
        <div >
            <iframe className="d-flex justify-content-center" src={process.env.PUBLIC_URL + '/vnpay/index.html'} seamless style={{width: '100%',height: '90vh'}}></iframe>
        </div>
    )
    else if(id == 2) return(
        <div>
            <div>Bằng việc truy cập và sử dụng dịch vụ, khách hàng đồng ý tuân thủ quy định sử dụng trang web của công ty.</div>
            <div>
            Công ty Nam Đà Thành bảo lưu việc chỉnh sửa, sửa đổi và bổ sung bất kỳ nội dung nào của quy định
sử dụng tại bất kỳ thời điểm nào nếu xét thấy cần thiết. Các chỉnh sửa, sửa đổi và bổ sung
sẽ có hiệu lực ngay khi được đăng tải trên trang web.
            </div>
            <ol>
                <li>Quy định sử dụng vé tại khu du lịch</li>
                <ul>
                    <li>Vé trẻ em dành cho khách có chiều cao từ 1m đến 1m4.</li>
                    <li>Vé người lớn dành cho khách có chiều cao trên 1m4.</li>
                    <li>Cung cấp thông tin đặt vé cho quầy lễ tân của khu du lịch và nhận vé của bạn.</li>
                    <li>Để giữ gìn vệ sinh chung, vui lòng không mang đồ tươi sông, đồ nấu nướng vào khu du lịch.</li>
                    <li>Không được mang thú cưng, loa kéo các loại, ..., loa có công suất lớn vào khu du lịch.</li>
                    <li>Quý khách vui lòng tuân thủ các quy định của khu du lịch.</li>
                    <li>Quý khách tự bảo quản mã booking/code vé sau khi xác nhận.</li>
                    <li>Vé chỉ có giá trị sử dụng trong ngày cho một lần vào cổng.</li>
                    <li>Giữ vé của bạn trong suốt quá trình sử dụng dịch vụ.</li>
                </ul>
                <li>Chính sách giao vận</li>
                <p>Sau khi thanh toán, khách hàng sẽ được tạo một mã vé dùng tại quầy kiểm tra. Khách hàng có thể tìm thấy trong mục tìm kiếm vẽ đã mua bằng thông tin cá nhân được dùng để mua vé và tải về bản pdf thông tin sử dụng mã vé.</p>
                <p>Khách hàng cung cấp thông tin mã vé của mình tại khu du lịch để được xác nhận vào cửa.</p>
                <li>Chính sách hoàn hủy, đổi trả</li>
                <p>Sau khi khách hàng thanh toán đơn hàng, vé đã mua sẽ không được phép hoàn trả hay đổi dưới bất kỳ trường hợp nào.</p>
                <li>Chính sách bảo mật</li>
                <p>Thông tin mà khách hàng được yêu cầu cung cấp bao gồm tên, số điện thoại và số chứng minh nhân dân hoặc số hộ chiếu. Những thông tin này được dùng để gắn vào mã vé sinh ra cho khách hàng đăng ký mua vé và hoàn toàn do khách hàng chịu trách nhiệm về việc cung cấp thông tin chính xác, công ty sẽ không kiểm tra những thông tin này.</p>
                <p>Thông tin mà khách hàng cung cấp sẽ được lưu trữ và sử dụng cho việc kiểm tra mã vé tại quầy khu du lịch.</p>
                <p>Mã đặt vé của khách hàng sẽ được lưu đến ngày sử dụng vé. Sau ngày sử dụng vé, mã vé sẽ hết tác dụng và có thể bị xóa bỏ.</p>
                <li>Quy định sử dụng trang web</li>
                <p>Quý khách tuân thủ chính xác quy trình mua vé trên trang web. Quý khách phải chịu trách nhiệm về bất kỳ hành động nào cố gây tổn thương, trục trặc trong quá trình mua vé, ví dụ như hack vào trang web.</p>
                <p>Thời gian bảo trì sửa đổi trang web sẽ được thông báo trên trang chủ, quý khách vui lòng không đặt vé gần khoảng thời gian này để tránh những trục trặc khi mua vé.</p>
            </ol>
        </div>
    )
    else if(id == 3){
        if(loading) return(
            <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
            </div>
        )
        else return(
            cart.map((value, index) => {
                return(
                    <div key={index} className="card">
                        <div className="card-body">
                            <h5 class="card-title">{value.name[`${lang.flag}`]}</h5>
                            <p className="card-text" dangerouslySetInnerHTML={{__html: value.des[`${lang.flag}`]}}></p>
                        </div>
                    </div>
                )
            })
        )
    }
}