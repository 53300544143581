import { useEffect, useState, useContext } from 'react';
import { EvolvedSearchCard, formatter, SearchCard, TicketCard, TicketCardEvolved } from '../components/Cards';
import { link } from './link';
import { redirect, useAsyncError, useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import './style.css';
import image from './qr.jpg';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import html2pdf from 'html2pdf.js';

import axios from 'axios';
import { LangContext } from '../components/Language';

export function PickTicket(){
    const navigate = useNavigate();
    const [cart, setCart] = useState([]);
    const [mode, setMode] = useState(-1);
    const lang = useContext(LangContext);
    ///////////// Ticket API
    const [loading, setLoading] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    console.log(link.baseURL);
    useEffect(() => {
        setLoading(true);
        axios.get(link.baseURL + '/app/ticket').then((res) => {
            console.log(res);
            setMode(res.data.mode);
            setCart(res.data.ticket);
            setLoading(false);
        }).catch((err) => {
            popup(lang.pick_fault);
            console.error(err);
        });
    }, []);

    const [validDate, setValidDate] = useState(false);
    const [paid, setPaid] = useState(false);
    const [bill, setBill] = useState(null);
    
    let sum = 0;
    const popup = (str) => {
        const container = document.getElementById("overlaycontainer");
        const newitem = document.createElement("div");
        newitem.innerHTML = str;
        newitem.className = "overlay";
        container.appendChild(newitem);
    }
    const onFinish = () => {
        let input = document.getElementById('name').value;
        if(input == undefined || input == ""){
            popup(`${lang.pick_lackname}`);
            return;
        }
        input = document.getElementById('phone').value;
        if(input == undefined || input == ""){
            popup(`${lang.pick_lackphone}`);
            return;
        }
        input = document.getElementById('uid').value;
        if(input == undefined || input == ""){
            popup(`${lang.pick_lackid}`);
            return;
        }
        let d = new Date(document.getElementById('date_on_ticket').value);
        let now = new Date();
        if(d == "Invalid Date"){
            popup(`${lang.pick_lackdate}`);
            return;
        }
        if(d.valueOf() < now.valueOf()) {
            popup(`${lang.pick_wrongdate}`);
            return;
        }
        let nextCart = cart.slice();
        setValidDate(true);
        setCart(nextCart);
    }
    const onAddClick = (i) => {
        let nextCart = cart.slice();
        nextCart[i].num = 1;
        setCart(nextCart);
        popup("Thành công!");
    }
    const onExcludeClick = (i) => {
        let nextCart = cart.slice();
        nextCart[i].num = 0;
        setCart(nextCart);
        popup("Thành công!");
    }
    const onPlusClick = (index, level) => {
        let nextCart = cart.slice();
        nextCart[index].num[level]++;
        setCart(nextCart);
        popup(`${lang.pick_success}`);
    }
    const onMinusClick = (index, level) => {
        let nextCart = cart.slice();
        if(nextCart[index].num[level] > 0) {
            nextCart[index].num[level]--;
            setCart(nextCart);
            popup(`${lang.pick_success}`);
        }
        else{
            popup("Bạn không thể giảm nhỏ hơn 0");
        }
    }
    const onSend = () => {
        setIsBusy(true);
        try{
            let rand = Math.floor(Math.random() * 10000);
            let d = new Date();
            let date_on_ticket = new Date(document.getElementById('date_on_ticket').value)
            let collection_id = "B" + d.toJSON() + "_" + rand;
            collection_id = collection_id.replace(/[.:-]/g, "");
            let o = {
                paid: 0,
                date_on_ticket: "" + date_on_ticket.toJSON().slice(0, 10),
                name: document.getElementById('name').value,
                phone: document.getElementById('phone').value,
                uid: document.getElementById('uid').value,
                money: sum,
                ticket: [],
                date_book: "" + d.toJSON(),
                booking_code: collection_id
            }

            document.getElementById('amount').value = sum;
            document.getElementById('orderId').value = collection_id;
            document.getElementById('orderDescription').value = document.getElementById('name').value + document.getElementById('phone').value + document.getElementById('uid').value
            
            cart.forEach((i) => {
                if(i.num[0] != 0 || i.num[1] != 0){
                    let temp = {};
                    temp.ticket_id = i.ticket_id;
                    temp.num = i.num;
                    temp.name = i.name;
                    o.ticket.push(temp);
                }
            })
            axios.post(link.baseURL + '/app/customer/buy', o, {
                headers:{
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                setBill(o);
                setPaid(true);
                setIsBusy(false);
                popup(`${lang.pick_success}`);
            }).catch(console.error);
        }
        catch(err){
            console.error(err);
            setIsBusy(false);
            popup(`${lang.pick_fault}`);
        }
    }

    const onPay = () => {
        document.getElementById('order').submit();
        
    }

    const handleDownloadText = () => {
        var element = document.getElementById('bill');
        var worker = html2pdf().from(element).save('ticket.pdf')
//         let text = `Họ và tên: ${bill.name}
// Số điện thoại: ${bill.phone}
// CMND: ${bill.uid}
// Vé:
// ${
//     bill.ticket.map(element => {
//         return(
//             ` - ${element.name}
//     Người lớn - Số lượng: ${element.num[0]}
//     Trẻ em - Số lượng: ${element.num[1]}`
//         )
//     })
// }
// Ngày đặt vé: ${bill.date_book}
// Giá: ${bill.money}
// Mã booking: ${bill.booking_code}
// Ngày đi trên vé: ${bill.date_on_ticket}`;
//         const blob = new Blob([text], { type: "text/plain;charset=utf-8" });
//         const url = URL.createObjectURL(blob);
//         const link = document.createElement('a');
//         link.href = url;
    
//         link.download = "text.txt"; // Specify the desired filename
//         link.click();
//         URL.revokeObjectURL(url);
    };
    if(loading) return (<Lottie style={{width: '100px'}} autoPlay loop animationData={require("../components/assets/loading.json")} />)
    return (
        <div>
            <form id='order' action={link.baseURL +'/order/create_payment_url'} method='POST'>
                <input type="hidden" name="amount" id='amount' />
                <input type="hidden" name="orderDescription" id='orderDescription' />
                <input type="hidden" name="orderId" id='orderId' />
                <input type="hidden" name="orderType" value='other' />
            </form>
        <div className='card p-3 m-3'>
            <h3>Các bước để có thể mua vé:</h3>
            <ul>
                <li>1. Nhập đầy đủ thông tin cá nhân cần thiết vào ô bên dưới.</li>
                <li>2. Chọn ngày sử dụng vé. Ấn nút hoàn thành</li>
                <li>3. Chọn tất cả vé bạn muốn sử dụng trong ngày ở dưới cùng và điều chỉnh số lượng</li>
                <li>4. Bấm vào nút "Thanh toán" để gửi yêu cầu đặt vé. Sau đó thanh toán qua mã QR code hiện ra và ghi nội dung chuyển tiền là tên, số điện thoại của bạn</li>
            </ul>
        </div>
        <div className='daypicker'>
            <h3>{lang.pick_request}</h3>
            <div className='d-flex gap-3 flex-wrap'>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">{lang.ticket_full_name}</label>
                    <input className='form-control' disabled={validDate} id='name' type='text' name='name' placeholder={lang.ticket_full_name} maxLength={100}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="phone" className="form-label">{lang.ticket_phone}</label>
                    <input className='form-control' disabled={validDate} id='phone' type='text' name='phone' placeholder={lang.ticket_phone} maxLength={20}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="uid" className="form-label">{lang.ticket_idcard}</label>
                    <input className='form-control' disabled={validDate} id='uid' type='text' name='uid' placeholder={lang.ticket_idcard} maxLength={100}/>
                </div>
                <div className="mb-3">
                    <label htmlFor="date_on_ticket" className="form-label">{lang.ticket_date_on_ticket}</label>
                    <input disabled={validDate} id='date_on_ticket' type='date' className='form-control'/>
                </div>
            </div>
            {
                validDate ? <button className='btn bg-success-subtle' onClick={() => {setValidDate(false)}}>{lang.pick_back}</button>
                :
                <button className='btn btn-success' onClick={onFinish}>{lang.pick_finish}</button>
            }
        </div>
        {
            validDate && (<>
            <div className="Shop">
                {
                    cart && cart.length != 0 ? cart.map((item, index) => {
                        if(item.num[0] != 0 || item.num[1] != 0){
                            if(mode == 1 && item.apply == 1) sum += item.price[2] * item.num[0] + item.price[3] * item.num[1];
                            else if((mode == 0 && item.apply == 1) || item.apply == 0) sum += item.price[0] * item.num[0] + item.price[1] * item.num[1];
                            else if(item.apply == 2) sum += item.price[4] * item.num[0] + item.price[5] * item.num[1];
                        } 
                            return (
                                <TicketCardEvolved key={index} value={item} mode={mode}
                                onMinus0={() => onMinusClick(index, 0)}
                                onPlus0={() => onPlusClick(index, 0)}
                                onMinus1={() => onMinusClick(index, 1)}
                                onPlus1={() => onPlusClick(index, 1)}/>
                            )
                        
                    }) : (
                        <div>Hiện tại không có vé trên hệ thống</div>
                    )
                }
            </div>
            {/* <div className="Shop">
                {
                    cart && cart.length != 0 ? cart.map((item, index) => {
                        if(item.num[0] == 0 && item.num[1] == 0){
                            //sum += item.price * item.num;
                            return (
                                <TicketCardEvolved key={index} value={item} mode={mode}
                                onMinus0={() => onMinusClick(index, 0)}
                                onPlus0={() => onPlusClick(index, 0)}
                                onMinus1={() => onMinusClick(index, 1)}
                                onPlus1={() => onPlusClick(index, 1)}/>
                            )
                        }
                    }) : (
                        <div>{lang.pick_fault}</div>
                    )
                }
            </div> */}
            <div className="SumMoney border border-top border-2">
                <h5>{lang.ticket_total}: <span>{formatter.format(sum)}</span></h5>
                {
                    sum != 0 && (
                        <>
                        {
                            isBusy ? <Lottie style={{width: '50px'}} autoPlay loop animationData={require("../components/assets/loading.json")} />: <button className="btn btn-success" onClick={onSend}>Gửi đăng ký</button>
                        }
                        </>
                    )
                }
            </div>
            </>)
        }
        <div className='overlaycontainer' id='overlaycontainer'>

        </div>
        {
            paid && (
                <div className='overlayBackground'>
                    <div className='overlayNotification'>
                        Hóa đơn đăng ký của bạn:
                        {
                            bill && <EvolvedSearchCard value={bill} id={'bill'}/>
                        }
                        <button onClick={handleDownloadText} className='btn btn-outline-success'>{lang.ticket_download}</button>
                        <button onClick={onPay} className='btn btn-outline-success'>Thanh toán</button>
                        {/* Bạn đã đăng ký xong. Vui lòng thanh toán số tiền {sum} đồng theo mã QR bên dưới và ghi tên cùng với số điện thoại của bạn vào nội dung chuyển tiền.
                        <div>
                            <img src={image} alt='Not found' width={300}/>
                        </div>
                        <button className='btn btn-success' onClick={() => {
                            setPaid(false);
                            navigate("/");
                        }}>{lang.pick_backhome}</button> */}
                    </div>
                </div>
            )
        }
        </div>
    )
}